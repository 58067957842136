import {
  Alert,
  Button,
  Input,
  isValidEmail,
  isValidNumber,
  isValidRequired
} from '@123-front/ui-kit';
import Axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Form from './form';
import './form.css';
export interface Plus123LeadData {
  company: string;
  email: string;
  name: string;
  phone: string;
}

export interface Plus123LeadErrors {
  email: string;
  phone: string;
}

const initialErrors: Plus123LeadErrors = {
  email: '',
  phone: '',
};

interface FormProps extends Plus123LeadData {
  sampleCompany: string;
  sampleEmail: string;
  sampleName: string;
  samplePhone: string;
  labelCompany: string;
  labelName: string;
  labelEmail: string;
  labelPhone: string;
  minPhoneLength: number;
  maxPhoneLength: number;
  countryCode: string;
  errorMessage: string;
  emailErrorMessage: string;
  phoneErrorMessage: string;
  focusEmailMessage: string;
  submitbutton: string;
  onRegistration: (formData: Plus123LeadData) => void;
}

const Plus123Form: React.FC<FormProps> = ({ onRegistration, ...props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiDomain
          }
        }
      }
    `,
  );
  const [company, setCompany] = useState(props.company);
  const [email, setEmail] = useState(props.email);
  const [name, setName] = useState(props.name);
  const [phone, setPhone] = useState(props.phone);
  const [errors, setErrors] = useState(initialErrors);
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isValid = (validationResults: Plus123LeadErrors) =>
    Object.values(validationResults).every((error) => error === '');
  const validate = (): Plus123LeadErrors => {
    const newErrors = {
      email: !isValidRequired(email) || !isValidEmail(email) ? props.emailErrorMessage : '',
      phone:
        !isValidRequired(phone) ||
        !isValidNumber(phone) ||
        phone.length < props.minPhoneLength ||
        phone.length > props.maxPhoneLength
          ? props.phoneErrorMessage
          : '',
    };
    setErrors(newErrors);
    return newErrors;
  };

  const handleClick = async () => {
    setSubmitted(true);
    const validationResults = validate();
    if (isValid(validationResults)) {
      setProcessing(true);
      try {
        await Axios.post(`//${site.siteMetadata.apiDomain}/api/quote?product=plus`, {
          company,
          name,
          phone,
          email,
          countryCode: props.countryCode,
          url: document.URL,
        });
        setProcessing(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        onRegistration({ company, name, phone, email });
      } catch (_) {
        setProcessing(false);
      }
    }
  };

  return (
    <Form noValidate>
      <Input
        label={props.labelCompany}
        placeholder={props.sampleCompany}
        value={company}
        onChange={({ target }) => setCompany(target.value)}
      />
      <Input
        label={props.labelName}
        placeholder={props.sampleName}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <Input
        label={props.labelEmail}
        placeholder={props.sampleEmail}
        value={email}
        type="email"
        info={props.focusEmailMessage}
        error={errors.email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <Input
        className={`phone-${props.countryCode}`}
        label={props.labelPhone}
        placeholder={props.samplePhone}
        value={phone}
        type="number"
        error={errors.phone}
        onChange={({ target }) => setPhone(target.value)}
      />
      {submitted && !isValid(errors) ? (
        <div style={{ paddingBottom: '24px' }}>
          <Alert type="error" text={props.errorMessage}></Alert>
        </div>
      ) : null}
      <Button block disabled={processing} onClick={handleClick}>
        {props.submitbutton}
      </Button>
    </Form>
  );
};

export default Plus123Form;
