import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Plus123Form, { Plus123LeadData } from '../../../components/123plus-form';
import FlexCenter from '../../../components/flex-center';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: Plus123LeadData = {
  company: '',
  name: '',
  email: '',
  phone: '',
};

const Plus123LandingCl: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "fig_api_form-hero-mob" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "fig_api_form-hero-dsk" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);
  return (
    <Layout gtmId="GTM-P2PPCL8">
      <SEO
        title="Alianzas Comerciales. Partners 123 | API +123Seguro Chile"
        siteTitle="123seguro"
        description="¡Conviértete en uno de nuestros Partners 123! Forma parte de nuestras Alianzas Comerciales y protege a tus clientes por medio de la API de +123."
      />
      <Hero
        title="BIENVENIDO A +123"
        subtitle="Protege a tus clientes\ny potencia tus ingresos"
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
      />
      <Container>
        <FlexCenter>
          <Column desktop={6}>
            {submitted ? (
              <SuccessNotice
                noticeText={`Pronto nos contactaremos contigo por WhatsApp (${leadInfo.phone}) o por mail (${leadInfo.email}).`}
                solicitudText="¡Solicitud enviada!"
                verifyText="¿No son tus datos?"
                onRequestChange={() => submit(false)}
                changeText="Modificarlos"
              />
            ) : (
              <Plus123Form
                {...leadInfo}
                labelCompany="COMPAÑÍA"
                sampleCompany="Ej.: Tech Co."
                labelName="NOMBRE"
                sampleName="Ej.: José López"
                labelEmail="CORREO ELECTRÓNICO"
                sampleEmail="Ej.: nombre@dominio.com"
                labelPhone="TELÉFONO CELULAR"
                samplePhone="Ej.: 922334455"
                minPhoneLength={7}
                maxPhoneLength={12}
                countryCode="cl"
                errorMessage="Por favor revisa los campos con error"
                emailErrorMessage="Ingresa un correo válido; ej.: nombre@dominio.com"
                phoneErrorMessage="Ingrese un teléfono válido; ej.: 922334455"
                focusEmailMessage="Correo al que enviaremos información"
                submitbutton="Solicitar información de +123"
                onRegistration={(formValues) => {
                  setLeadInfo(formValues);
                  submit(true);
                }}
              />
            )}
          </Column>
        </FlexCenter>
      </Container>
    </Layout>
  );
};

export default Plus123LandingCl;
